;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"dd8b739d5456322a28ea797d5bd3e4469d9e5dd1"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import { replayIntegration } from "@sentry/nextjs";

import { initSentry } from "./domains/sentry";

initSentry({
  integrations: [
    replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
    }),
  ],
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0,
});
