import { type BrowserOptions, init } from "@sentry/nextjs";

export const initSentry = (options?: BrowserOptions) => {
  init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === "true",
    normalizeDepth: 10,
    tracesSampleRate: 0.1,
    ...options,
  });
};
